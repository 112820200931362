import React from 'react'

import styles from './404.module.css'
import '../style.module.css'

import Skeleton from '../components/Skeleton'
import config from '../config'

const GrowWithUs = () => {

  const {
    404: {
      // title: pageTitle,
      bannerImage,
      customHero,
    },
  } = config


  return (
    <Skeleton
      disableFooterImage={true}
      bannerImage={bannerImage}
      customHero={customHero}
    >
      <div className={styles.blankSpace} />
    </Skeleton>
  )
}

export default GrowWithUs
